<template>
  <div class="" v-loading.fullscreen.lock="loading">

    <div class="glob">

      <div v-if="step === 3" class="force_right force_bottom">
        <button @click="form_picture_submit">Continuer</button>
      </div>
      <div class="recherche">

        <div v-if="currentPlace">

          <!--      <div v-for="n in currentPlace.photos" :key="n">-->
          <!--        {{ n.getUrl() }}-->
          <!--      </div>-->
        </div>


        <el-form v-if="step === 3" ref="form" class="form_picture" label-position="top" label-width="120px">
          <el-form-item label="Sélectionnez votre image de couverture">
<!--            <el-upload-->
<!--                ref="upload"-->
<!--                :action="$api_ip + 'bo/boutique/image/cover'"-->
<!--                :before-upload="beforeAvatarUpload"-->
<!--                :multiple="false"-->
<!--                :on-error="error"-->
<!--                :on-success="handleAvatarSuccess"-->
<!--                :show-file-list="false"-->
<!--                class="upload_block"-->
<!--            >-->
<!--              <el-image v-if="imageUrl" :src="imageUrl" class="img" fit="cover"></el-image>-->
<!--              <el-image v-if="img" :src="$static_patch + img" class="img" fit="cover"></el-image>-->
<!--              <i v-else class="el-icon-plus upload_block-uploader-icon"><span>Largeur 1920 pixels minimum</span></i>-->
<!--            </el-upload>-->
            <div class="upload_block">
              <input @change="upload_img" type="file" id="file" accept="image/*">
              <!--                <img v-if="imageUrl" :src="imageUrl" alt="upload">-->
              <img v-if="img_key" :src="$img_path + img_key" alt="upload">
              <i v-else class="ico el-icon-plus upload_block-uploader-icon"></i>
            </div>

            <div v-if="currentPlace && currentPlace.photos && !imageUrl">
              <div v-for="(n, k) in currentPlace.photos" :key="k" :class="{ select: imgSelect === k }" class="select_image">
                <el-image :src="n.getUrl()" class="img_load" fit="cover" @click="select_img(n.getUrl(), k)"></el-image>
              </div>
            </div>

          </el-form-item>

        </el-form>

        <el-form v-if="step === 2" ref="form" class="form_adress" label-position="top" label-width="120px" @submit.native.prevent="form_adress_validate">


          <el-form-item label="Nom de votre restaurant" prop="name">
            <el-input v-model="name" placeholder="Nom de votre restaurant"></el-input>
          </el-form-item>

          <el-form-item label="Type de Cuisine">
            <el-select v-model="activity" placeholder="Type de Cuisine">
              <el-option
                  v-for="item in $activity"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="Adresse de votre restaurant">
            <div class="rue">
              <el-input v-model="street_number" class="street_number" placeholder="Numéro"></el-input>
              <el-input v-model="route" class="route_c" placeholder="Rue"></el-input>
            </div>

            <div class="ville">
              <el-input v-model="postal_code" class="postal_code" placeholder="Code postal"></el-input>
              <el-input v-model="locality" class="country" placeholder="Ville"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="Numéro de téléphone de votre restaurant">
            <vue-tel-input v-model="international_phone_number" placeholder="Numéro de téléphone" @validate="phone_validate_method"></vue-tel-input>
          </el-form-item>

          <div class="force_right">
            <button>Continuer</button>
          </div>
        </el-form>

        <div v-if="step === 1">
          <title_cat>Recherchons votre restaurant</title_cat>
          <p>Afin de vous faire gagner du temps, nous vous proposons d'obtenir la plupart des informations nécessaires à
            la mise en ligne de votre restaurant en la recherchant grâce à Google. 
            <a style="margin-top: 8px; display: inline-block" href="" @click.prevent="step = 2">Mon restaurant n'est pas sur Google</a></p>
          <div class="input">
            <div class="ico_main">
              <svg aria-hidden="true" fill="none" focusable="false" height="24px" role="presentation" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5834 5.16602C14.5001 2.08268 9.50008 2.08268 6.41675 5.16602C3.33341 8.24935 3.33341 13.3327 6.41675 16.416L12.0001 21.9993L17.5834 16.3327C20.6667 13.3327 20.6667 8.24935 17.5834 5.16602ZM12.0001 12.416C11.0834 12.416 10.3334 11.666 10.3334 10.7493C10.3334 9.83268 11.0834 9.08268 12.0001 9.08268C12.9167 9.08268 13.6667 9.83268 13.6667 10.7493C13.6667 11.666 12.9167 12.416 12.0001 12.416Z" fill="#000000"></path>
              </svg>
            </div>
            <gmap-autocomplete :options="{type: 'restaurant'}" placeholder="Rechercher votre restaurant" autofocus
                               @place_changed="setPlace">
            </gmap-autocomplete>
          </div>
        </div>

      </div>

    </div>
    <gmap-map
        :center="center"
        :options="{
           zoomControl: false,
           mapTypeControl: false,
           draggable: false,
           scaleControl: false,
           streetViewControl: false,
           rotateControl: false,
           fullscreenControl: false,
           disableDefaultUi: false
         }"
        :zoom="12"
        class="map"
        style="width:100%;  height: 400px;"
    >
      <gmap-marker
          v-for="(m, index) in markers"
          :key="index"
          :position="m.position"
          @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>

import { VueTelInput } from 'vue-tel-input'

export default {
  name: "GoogleMap", data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: {lat: 48.866, lng: 2.261}, markers: [], places: [], currentPlace: null,

      name: '', street_number: '', route: '', locality: '', departement: '', region: '', country: '', postal_code: '', international_phone_number: '',
      national_phone_number: '',

      activity: null,

      img_key: null, imgSelect: null,

      imgGoogle: null,

      phone_validate: false,

      imageUrl: null,
      geo: {},

      step: 1,
      loading: false

    };
  },
  components: {
    VueTelInput
  },
  mounted() {
    // this.geolocate();
    if(this.$store.state.user.user.boutique) {
      this.$router.push({ name: 'bo_commandes_enattente' })
    }
  },

  methods: {
    form_adress_validate() {
      if (!this.name || !this.street_number || !this.route || !this.postal_code || !this.locality || !this.international_phone_number || !this.activity) {
        this.$notify({title: 'Il manque des informations', offset: this.$notifyOffest, type: 'error'});
        return
      }

      if (!this.phone_validate || this.phone_validate.isValid !== true) {
        this.$notify({title: "Erreur avec le numéro de téléphone", offset: this.$notifyOffest, type: 'error'});
        return
      }

      this.international_phone_number = this.phone_validate.number.e164
      this.national_phone_number = this.phone_validate.number.national

      this.step = 3

    }, form_picture_submit() {
      if (!this.img_key && !this.imgGoogle) {
        this.$notify({title: "Il manque l'image de couverture", offset: this.$notifyOffest, type: 'error'});
        return
      }
      this.loading = true

      this.$http.post('boutique/create', {
        name: this.name,
        street_number: this.street_number,
        route: this.route,
        postal_code: this.postal_code,
        locality: this.locality,
        international_phone_number: this.international_phone_number,
        national_phone_number: this.national_phone_number,
        activity: this.activity,
        img_key: this.img_key,
        website: this.website,
        geo: this.geo,
        imgGoogle: this.imgGoogle,
      }).then((response) => {
        // localStorage.setItem("bo_boutique_id", response.data)
        this.$bo_boutique_id = response.data
        this.$router.push({ name: 'bo_produits' })
        this.$store.dispatch("user/init");
      }).catch((e) => {
        console.log(e)
        this.$notify({title: e.response.data, offset: this.$notifyOffest, type: 'error'});
        this.step = 2
        this.loading = false
      })
      
      
    }, phone_validate_method(data) {
      this.phone_validate = data
    },

    upload_img(e) {
      this.$upload_img(e, 1080, 'boutique').then((d) => {
        console.log(d)
        this.imageUrl = d.blob
        this.img_key = d.key
      })
    },
    // handleAvatarSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    //   this.img = res
    // },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    //   const isLt2M = file.size / 1024 / 1024 < 200;
    //
    //   if (!isJPG) this.$message.error('Avatar picture must be JPG format!');
    //   if (!isLt2M) this.$message.error('Avatar picture size can not exceed 2MB!');
    //
    //   return isJPG && isLt2M;
    // }, 
    setPlace(place) {
      this.currentPlace = place;

      let r = this.placeToAddress(place)

      if (r['country'] && r['country'].long_name !== 'France') {
        this.$notify({title: 'Nous ne proposons pas encore nos services en dehors de France', offset: this.$notifyOffest, type: 'error'});
        return
      }
      console.log(place)

      if (r['street_number']) this.street_number = r['street_number'].long_name
      if (r['route']) this.route = r['route'].long_name
      if (r['postal_code']) this.postal_code = r['postal_code'].long_name
      if (r['locality']) this.locality = r['locality'].long_name
      if (r['departement']) this.departement = r['departement'].long_name
      if (r['region']) this.region = r['region'].long_name
      if (r['country']) this.country = r['country'].long_name

      this.name = this.currentPlace['name']
      this.website = this.currentPlace['website']
      this.international_phone_number = this.currentPlace['international_phone_number']
      this.geo = {
        lat: this.currentPlace.geometry.location.lat(), lng: this.currentPlace.geometry.location.lng()
      }

      this.addMarker();
      this.step = 2
    }, addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(), lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({position: marker});
        this.places.push(this.currentPlace);
        this.center = marker;
      }
    }, geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude, lng: position.coords.longitude
        };
      });
    }, select_img(n, k) {
      this.imgGoogle = n
      this.imgSelect = k
    }, placeToAddress(place) {
      var address = {};
      place.address_components.forEach(function (c) {
        switch (c.types[0]) {
          case 'street_number':
            address.street_number = c;
            break;
          case 'route':
            address.route = c;
            break;
          case 'neighborhood':
          case 'locality':    // North Hollywood or Los Angeles?
            address.locality = c;
            break;
          case 'administrative_area_level_2':     //  Note some countries don't have states
            address.departement = c;
            break;
          case 'administrative_area_level_1':     //  Note some countries don't have states
            address.region = c;
            break;
          case 'postal_code':
            address.postal_code = c;
            break;
          case 'country':
            address.country = c;
            break;
        }
      });

      return address;
    }
  }
};
</script>

<style scoped>

.upload_block { position: relative; border: 1px dashed #DCDFE6; border-radius: 4px; background: #f9f9f9; cursor: pointer; overflow: hidden }
.upload_block:hover { border-color: #C0C4CC }
.upload_block input { position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0; cursor: pointer; z-index: 5; width: 100%; }
.upload_block img { position: absolute; height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%) }
.upload_block i { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) }

.input { display: flex; align-items: center; width: 100%; background: #eee; position: relative; }
.input .ico_main { margin: 0 16px; position: absolute; top: 0; bottom: 0; display: flex; align-items: center }
.input input { width: 100%; height: 50px; border: none; background: none; outline: none; padding-left: 50px; font-size: 12px; transition: all .05s ease-in-out }
.input:after { border-bottom: 2px solid #111; position: absolute; bottom: 0; left: 0; right: 0; content: '' }

.vue-map-container { height: calc((var(--vh, 1vh) * 100) - 122px) !important; }

.form_adress .rue { display: flex; margin: 2% 0 }
.form_adress .rue .street_number { width: 20%; }
.form_adress .rue .route_c { width: 78%; margin-left: 2%; }

.form_adress .ville { display: flex; margin: 2% 0 }
.form_adress .ville .postal_code { width: 30%; }
.form_adress .ville .country { width: 68%; margin-left: 2%; }

.upload_block { height: 0; padding-bottom: 56.25%; position: relative; }
.upload_block:before { content: 'Ou ajoutez votre photo'; position: absolute; top: 0; left: 10px; color: var(--black-sub) }
.select_image { height: 0; padding-bottom: 47.64%; background: #eee; margin-top: 10px; overflow: hidden; position: relative; cursor: pointer; border-radius: 1px; overflow: hidden;
  transition: .05s all ease-in-out
}
.select_image .el-image { position: absolute; top: 50%; transform: translateY(-50%) }
.select_image.select { transform: scale(1.05); border-bottom: 4px solid var(--orange) }

.map { display: none }

.glob { position: relative; }
.recherche { padding: 0 var(--padding); position: relative; }

.form_picture { padding-bottom: 80px; }

.recherche { max-height: calc((var(--vh, 1vh) * 100) - 72px); overflow-y: auto; }
.recherche p { font-weight: 400; font-size: 14px; padding-bottom: 14px; }

@media only screen and (min-width: 800px) {
  .glob { background: #fff; position: absolute; top: 120px; left: var(--padding); z-index: 10; width: 500px; box-shadow: 2px 2px 4px rgba(20, 20, 20, .3);}
  .recherche { max-height: 80vh; padding: 24px 48px 32px 48px; }
  .recherche p { font-size: 14px; line-height: 26px; }
  .vue-map-container { height: calc((var(--vh, 1vh) * 100) - 72px) !important; }

  .map { display: block }

}

/*.pac-target-input { width: 100%; height: 50px; border: none; background: none; outline: none; padding-left: 50px; transition: all .05s ease-in-out  }*/
/*.map { position: fixed; top: 0; right: 0; bottom: 0; left: 0; }*/
</style>